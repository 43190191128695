
import { Component, Vue, Inject, Provide, Watch } from "vue-property-decorator";
import store from "@/store";
type UserData = typeof store.state.UserData;
@Component
export default class Dashboard extends Vue {
  @Inject() readonly reload!: () => void;
  @Provide("reload") reloadInject = this.reload;
  @Provide("refresh") refreshInject = this.refresh;
  @Watch("currentUser")
  onCurrentUserChanged(user: UserData) {
    if (user.user_type === "student") {
      this.loadStudentSider();
    } else if (user.user_type === "teacher") {
      this.loadTeacherSider();
    }
  }
  course_prefix = "course-";
  routeActiveName = "";
  isCollapsed = false;
  isRefresh = true;
  enrolledUnits: any[] = [];
  teachingUnits = [];

  get currentUser() {
    return this.$store.getters.getCurrentUser;
  }
  get campus() {
    return this.currentUser.campus;
  }
  get faculty() {
    return this.currentUser.faculty;
  }
  get display_name() {
    return this.currentUser.display_name;
  }
  get privilege() {
    // console.log(this.$store.getters.getCurrentUser);
    return this.currentUser.privilege;
  }
  get menuitemClasses() {
    return ["menu-item", this.isCollapsed ? "collapsed-menu" : ""];
  }
  get rotateIcon() {
    return ["menu-icon", this.isCollapsed ? "rotate-icon" : ""];
  }
  refresh() {
    this.isRefresh = false;
    this.$nextTick(function () {
      this.isRefresh = true;
    });
  }
  logout() {
    this.$store.commit("logout");
    this.$router.push({ name: "login" });
  }
  handleClick(name: string) {
    switch (name) {
      case "logout":
        this.logout();
        break;
    }
  }
  onMenuSelect(name: string) {
    this.routeActiveName = name;
    // Menus having name started with 'course-' are the unitInfo
    if (name.startsWith(this.course_prefix)) {
      this.$router
        .push({
          path: "/unitinfo",
          query: {
            id: name.slice(this.course_prefix.length)
          }
        })
        .catch(() => {});
    } else {
      this.$router.push(`/${name}`).catch(() => {});
    }
  }
  toHome() {
    this.$router.push({ name: "dashboard" }).catch(() => {});
  }
  collapsedSider() {
    this.isCollapsed = !this.isCollapsed;
  }
  loadStudentSider() {
    this.api.course.orderList.getOrderByCurrentStudent().then(async resp => {
      if (resp) {
        const courseRequests = resp.data
          .filter(item => item.paid)
          .map(course => this.api.course.get(course.course_code));
        const responseList =
          (await this.axios.all(courseRequests).catch(() => {})) || [];
        this.enrolledUnits = responseList
          .filter(val => val != undefined)
          .map(resp => resp!.data);
      }
    });
  }
  loadTeacherSider() {
    this.api.course.courseList.getByCurrentTeacher().then(resp => {
      if (resp) {
        this.enrolledUnits = resp.data;
      }
    });
  }
  created() {
    const user_type = this.currentUser.user_type;
    if (user_type === "student") {
      this.loadStudentSider();
    } else if (user_type === "teacher") {
      this.loadTeacherSider();
    }
  }
}
